<template>
  <div style="display: inline-block">
    <b-button
      variant="primary"
      class="mr-2 confirm-purchase"
      :disabled="isCanSubmit || disabled"
      @click="onShowModal()"
      >Mass Confirmed
      <i class="far fa-check-circle"></i>
      <!-- <b-icon icon="folder-symlink" aria-hidden="true">
        </b-icon
    > -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Mass Confirmed"
      id="purchase_overview_confirm_mass"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <p>
          Are you sure to change the status to "Confirmed"?
        </p>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

export default {
  name: 'ConfirmPurchaseoOverview',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false
    };
  },
  props: ['selectData', 'ids', 'disabled', 'initDataFn'],
  // inheritAttrs: false,
  components: {},
  methods: {
    onShowModal() {
      this.showModal = true;
      this.submitDisabled = false;
    },
    submit() {
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.purchase
        .purchaseOverviewConfirmMass({
          purchase_overview_ids: this.ids
        })
        .then((data) => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          // location.reload();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.initDataFn();
        })
        .catch((error) => {
          console.log(error.message);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {}
  },

  computed: {
    isCanSubmit() {
      if (this.selectData.length == 0) {
        return true;
      } else {
        let confirmArr = [],
          orderArr = [],
          passArr = [];
        this.selectData.map((item) => {
          if (item.status === 'Reserved') {
            confirmArr.push(item);
            if (
              orderArr.indexOf(item.order_information.cps_order_number) ===
                -1 &&
              item.order_information.cps_order_number.substr(0, 2) === '16'
            ) {
              orderArr.push(item.order_information.cps_order_number);
            }
            if (item.order_information.cps_order_number.substr(0, 2) !== '16') {
              passArr.push(item.order_information.cps_order_number);
            }
          }
        });
        if (confirmArr.length != this.selectData.length) return true;
        if (orderArr.length == 0 || orderArr.length > 1) return true;
        if (passArr.length > 0) return true;
      }
      return false;
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
</style>